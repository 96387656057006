.wrapper-banners{
  position: relative;
  width: 100%;
  height: 760px;
  overflow: hidden;
  @include respond-to('small'){
    height: 460px;
  }
}

.wrap-banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('small'){
    position: static;
  }
}

.banner-bg{
  width: 100%;
  min-height: 760px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('small'){
    min-height: 460px;
  }
}

.item-banner-title{
  position: absolute;
  bottom: 30px;
  left: 50%;
  @include transform(translateX(-50%));
  max-width: 1000px;
  background-color: rgba(255,255,255,0.8);
  z-index: 2;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  & > div{
    & > p{
      font-size: 22px;
      color: #471309;
      line-height: 1;
    }
  }
}

.item-banner-title__btn{
  width: 100px;
  height: 40px;
  background-color: #013d81;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  display: inline-block;
  line-height: 2.3;
  &:active{
    @include btn-effect-active;
  }
}
