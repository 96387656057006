.wrapper-all-content{
  background-color:#fff;
  position: relative;
  width: 100%;
}

.main-wrap{
  padding: 40px 0;
}

.hp-wrap-box-work{
  position: relative;
  max-width: 480px;
  width: 100%;
  margin-bottom: 30px;
  @include respond-to('medium'){
    margin-left: auto;
    margin-right: auto;
  }
}

.box-work__title{
  font-size: 25px;
  color: #6d6c6c;
  font-weight: 300;
}

.box-work__hr{
  border-color: #e3e3e3;
  margin-top: 15px;
  margin-bottom: 20px;
}

.box-work__image{
  position: relative;
  max-height: 180px;
  width: 100%;
  overflow: hidden;
}

.box-work__image-item{
  width: 100%;
  height: 180px;
  background-position: center center;
  background-size: cover;
}

.box-work__content{
  position: relative;
  height: 100px;
  overflow: hidden;
  & > p{
    font-size: 17px;
    color: #6d6c6c;
    font-weight: 300;
    margin: 0;
    line-height: 1;
  }
}

.box-work__btn{
  display: inline-block;
  background-color: #013d81;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.7;
  width: 90px;
  height: 30px;
  &:active{
    @include btn-effect-active;
  }
}

.hp-hr{
  border-color: #e3e3e3;
  margin: 0;
}

.hp-section-box-bottom{
  margin-top: 50px;
}

.hp-title{
  font-size: 25px;
  color: #6d6c6c;
  font-weight: 300;
  margin: 20px 0;
}

.txt-style{
  & > p{
    font-size: 17px;
    color: #6d6c6c;
    font-weight: 300;
    margin: 0;
  }
}
