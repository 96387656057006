
.main-header{
  width:100%;
  z-index:1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  height: 90px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
  @include transition(all 0.4s ease);
  .header-mobile{
    display:none;
  }
  @include respond-to('medium'){
    height: 60px;
    .header-desktop{
      display: none;
    }
    .header-mobile{
      display:block;
      & > a{
        margin-right: 20px;
        margin-top: 17px;
        display: inline-block;
        float: left;
        & > img{
          width: 150px;
        }
      }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
        background-color: #013d81;
      }
      .hamburger{
        padding: 15px;
      }
    }
  }
}

.mobile-tel{
  display: inline-block;
  margin-top: 13px;
  i{
    color: #013d81;
    font-size: 42px;
  }
}

.sb-slidebar{
  background-color: #f5f5f5;
  z-index: 9999999;
}

.mobile-menu-btn{
  float: right;
}

.sb-right .sb-menu,
.sb-left .sb-menu{
  & li{
    border-bottom: 0;
    & a{
      color: #2d2a6e;
      border-bottom: 1px solid rgba(0,0,0,.1);
      font-size: 15px;
      &:hover{
        background-color: #eee;
      }
    }
    & ul{
      li{
        padding-right: 0;
      }
    }
  }
  & ul{
    padding: 0;
    li > a{
      padding-right: 35px;
    }
  }
}

.header-desktop{
  height: 100%;
}

.main-navi{
  display: inline-block;
  padding-top: 45px;
  float: left;
  & > ul{
    display: inline-block;
    list-style: none;
    padding: 0;
    & > li{
      float: right;
      position: relative;
      padding-bottom: 18px;
      &:last-child{
        & .main-navi__slash{
          display: none;
        }
      }
      & > a{
        font-size: 21px;
        color: #72463b;
      }
      &:hover{
        & > ul{
          display: block;
        }
      }
      & > ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: none;
        position: absolute;
        top: 45px;
        right: 0;
        background-color: rgba(255,255,255,0.9);
        border: 1px solid #dedede;
        & > li{
          border-bottom: 1px solid #ddd;
          padding: 5px 15px;
          font-size: 17px;
          color: #222222;
          display: block;
          white-space: nowrap;
          & > a{
            font-size: 17px;
            color: #222222;
            display: block;
            white-space: nowrap;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.main-navi__slash{
  color: #72463b;
  margin: 0 10px;
  position: relative;
  vertical-align: top;
  &:after{
    position: absolute;
    top: 4px;
    left: 0;
    content: "";
    width: 1px;
    height: 22px;
    background-color: #72463b;
  }
}

.logo{
  float: right;
  margin-top: 35px;
}

#fixed-top.scrolling{
  height: 60px;
  background-color: rgba(255,255,255,0.9);
  & .main-navi{
    padding-top: 22px;
  }
  & .header-desktop{
    .logo{
      margin-top: 16px;
      & > a{
        & > img{
          width: 160px;
        }
      }
    }
  }
}

/*LTR*/
html[dir="ltr"]{
  .mobile-menu-btn,
  .mobile-tel{
    float: left;
  }

  .main-header .header-mobile{
    & > a{
      float: right;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .main-navi{
    & > ul{
      & > li{
        float: left;
        padding-bottom: 23px;
        & > a{
          font-size: 17px;
        }
        & > ul{
          right: auto;
          left: 0;
        }
      }
    }
  }
}
