.wrap-gallery{
  margin-top: 30px;
}

.wrap-box-img{
  max-width: 480px;
  width: 100%;
  max-height: 300px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  .img{
    @include transition(all 0.4s);
    width: 100%;
    height: 300px;
    background-position: center center;
    background-size: cover;
  }
  &:hover{
    .img{
      @include transform(scale(1.08));
    }
  }
}

.wrap-box-img-cat{
  max-width: 480px;
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  display: block;
  &:hover{
    .wrap-box-img-cat__item{
      @include transform(scale(1.08));
    }
  }
}

.wrap-box-img-cat__item{
  width: 100%;
  height: 300px;
  background-position: center center;
  background-size: cover;
  @include transition(all 0.4s);
}

.wrap-box-img-cat__title{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(255,255,255,0.8);
  font-size: 22px;
  color: #6d6c6c;
}
