footer{
  padding-top: 70px;
  width:100%;
  position: relative;
  z-index: 1;
}

.ftr-wrap{
  background-color: #171717;
  -webkit-box-shadow: inset 0px 5px 10px 0px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 5px 10px 0px rgba(0,0,0,1);
  box-shadow: inset 0px 5px 10px 0px rgba(0,0,0,1);
  padding-top: 50px;
  padding-bottom: 40px;
}

.ftr-wrap-links{
  & > ul{
    padding: 0;
    list-style: none;
    & > li{
        margin-bottom: 10px;
      & > a{
        font-size: 17px;
        color: #8f8f8f;
        &:hover{
          color: #fff;
        }
      }
    }
  }
}

.hr-footer-bottom{
  border: 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #252525;
  margin-top: 50px;
  margin-bottom: 40px;
}

.credit-info{
  display: inline-block;
  line-height: 3;
  @include respond-to('small'){
    display: block;
  }
}

.credit-info-txt{
  display: inline-block;
  @include respond-to('small'){
    display: block;
    text-align: center;
  }
  & > p{
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
}

.social{
  float: right;
  margin-left: 15px;
  @include respond-to('small'){
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
  }
}

.ftr-form__title{
  font-size: 17px;
  color: #8f8f8f;
  margin-bottom: 15px;
}
.ftr-wrap-input{
  position: relative;
  width: 200px;
  margin-bottom: 5px;
  @include respond-to('medium'){
    margin-right: auto;
    margin-left: auto;
  }
  &:after{
    //content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    background-color: #2d2d2d;
    width: 100%;
    height: 1px;
  }
}
.ftr-form__input{
  width: 100%;
  height: 40px;
  border: 1px solid #5f5f5f;
  color: #fff;
  font-size: 17px;
  padding: 0 10px;
  background-color: #101010;
  position: relative;
  @include border-radius(3px);
  @include placeholder{
    color: #fff;
    opacity: 1;
    @include transition(opacity 0.4s ease);
  }
  &:focus{
    @include placeholder{
      opacity: 0;
      @include transition(opacity 0.4s ease);
    }
  }
}

.ftr-form__textarea{
  height: 130px;
  resize: none;
  padding-top: 10px;
}

.ftr-form__btn{
  width: 95px;
  height: 40px;
  background-color: #eeeeee;
  color: #000;
  border: 1px solid #fff;
  @include border-radius(3px);
  font-size: 18px;
  margin-top: 10px;
  &:active{
    @include btn-effect-active;
  }
}

/*LTR*/
html[dir="ltr"]{
  .ltr__pull-left{
    float: left !important;
  }
  .ltr__pull-right{
    float: right !important;
  }
  .ftr-wrap-input{
    text-align: right;
  }
  .social{
    float: left;
    margin-right: 15px;
    margin-left: 0;
  }
  .mobile-float-center{
    @include respond-to('medium'){
      float: none !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .mobile-text-center{
    @include respond-to('medium'){
      text-align: center;
    }
  }
}


/*responsive*/
.mobile-float-center{
  @include respond-to('medium'){
    float: none !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.mobile-text-center{
  @include respond-to('medium'){
    text-align: center;
  }
}
