.wrapper-not-banner{
  padding-top: 110px;
  @include respond-to('medium'){
    padding-top: 70px;
  }
}

.content-title{
  font-size: 25px;
  color: #6d6c6c;
  margin-bottom: 30px;
}

.content-text{
  & > p{
    font-size: 17px;
    color: #6d6c6c;
    & > img{
      margin: 20px 20px 20px 0;
    }
  }
}
